@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
    display: none;
}

html::-webkit-scrollbar {
    display: none;
}

body,html {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
